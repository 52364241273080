import httpRequest from "@/utils/request";
import {aclMenuLeftApi} from "@/utils/api";
import {initBackendRoutesData} from "@/utils/fun";

export default {
	namespaced: true,
	state: {
		menuCollapse: false,
		leftNavMenuData: []
	},
	getters: {
	
	},
	mutations: {
		setMenuCollapse(state) {
			state.menuCollapse = !state.menuCollapse
		},
		setLeftNavMenuData(state, leftNavMenuData) {
			state.leftNavMenuData = leftNavMenuData
		}
	},
	actions: {
		async getLeftMenus(context) {
			await httpRequest(aclMenuLeftApi).then(res => {
				context.commit('setLeftNavMenuData', res.data)
				const routesList = initBackendRoutesData(res.data)
				context.commit('setRoutesList', routesList, {root: true})
			})
		}
	}
}
