export default {
	namespaced: true,
	state: {
		tableOptions: {
			stripe: true, // 是否为斑马纹
			size: 'large', //Table 的尺寸 large / default /small
			showHeader: true, // 是否显示表头,
			tooltipEffect: 'light', //  tooltip effect 属性   dark / light,
			showPagination: true, 	// 是否显示分页
			paginationConfig: {},	// 分页配置
			rowKey: '' // 行数据的 Key，用来优化 Table 的渲染。
		},
		
		paginationConfig: {
			total: 0, // 总条目数
			currentPage: 1, // 当前页数，支持 v-model 双向绑定
			pageSize: 15, // 每页显示条目个数，支持 v-model 双向绑定
			pageSizes: [15, 30, 50, 100] , // 每页显示个数选择器的选项设置
			layout: 'total, sizes, prev, pager, next, jumper', // 组件布局，子组件名用逗号分隔
			background: true // 是否为分页按钮添加背景色
		}
	}
}
