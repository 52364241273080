/******************系统设置 - 菜单管理 **********************/
export const aclMenuEditApi = '/acl/menu/edit'			// 添加、编辑菜单
export const aclMenuSaveApi = '/acl/menu/save'			// 保存菜单
export const aclMeneDeleteApi = '/acl/menu/delete'		// 删除菜单
export const aclMenuListApi = '/acl/menu/list'			// 菜单列表

export const aclMenuLeftApi = '/acl/menu/tableft'		// 左侧导航菜单权限

/******************系统设置 - 用户管理 **********************/
export const aclUserListApi = '/acl/user/list'			// 用户列表
export const aclUserEditApi = '/acl/user/edit'			// 用户编辑
export const aclUserSaveApi = '/acl/user/save'			// 用户保存
export const aclUserDeleteApi = '/acl/user/delete'		// 用户删除

/******************系统设置 - 角色管理 **********************/
export const aclRoleListApi = '/acl/role/list'			// 角色列表
export const aclRoleEditApi = '/acl/role/edit'			// 角色编辑
export const aclRoleSaveApi = '/acl/role/save'			// 角色保存
export const aclRoleDeleteApi = '/acl/role/delete'		// 角色删除

/******************系统设置 - 合作方管理 **********************/
export const signatoryInfoListApi = '/signatory/info/list'			// 合作方列表
export const signatoryInfoEditApi = '/signatory/info/edit'			// 合作方编辑
export const signatoryInfoSaveApi = '/signatory/info/save'			// 合作方保存
export const signatoryInfoDeleteApi = '/signatory/info/delete'		// 合作方删除

/******************系统设置 - 生物样本中心管理 **********************/
export const sampleSignatoryInfoListApi = '/signatory/info/samplelist'			// 生物样本中心列表
export const sampleSignatoryInfoEditApi = '/signatory/info/sampleedit'			// 生物样本中心编辑
export const sampleSignatoryInfoSaveApi = '/signatory/info/samplesave'			// 生物样本中心保存
export const sampleSignatoryInfoDeleteApi = '/signatory/info/sampledelete'		// 生物样本中心删除

/******************系统设置 - 项目管理 **********************/
export const itemInfoListApi = '/item/info/list'				// 项目列表
export const itemInfoEditApi = '/item/info/edit'				// 项目编辑
export const itemInfoSaveApi = '/item/info/save'				// 项目保存
export const itemInfoDeleteApi = '/item/info/delete'			// 项目删除

export const itemStateListApi = '/item/state/list'				// 项目状态列表
export const itemStateEditApi = '/item/state/edit'				// 项目状态编辑
export const itemStateSaveApi = '/item/state/save'				// 项目状态保存
export const itemStateDeleteApi = '/item/state/delete'			// 项目状态删除

/******************箱子管理**********************/
export const trallBoxListApi = '/trall/box/list'				// 箱子列表
export const trallBoxEditApi = '/trall/box/edit'				// 箱子编辑
export const trallBoxSaveApi = '/trall/box/save'				// 箱子保存
export const trallBoxDeleteApi = '/trall/box/delete'			// 箱子删除

/******************产品管理**********************/
export const trallProductListApi = '/trall/product/list'				// 产品列表
export const trallProductEditApi = '/trall/product/edit'				// 产品编辑
export const trallProductSaveApi = '/trall/product/save'				// 产品保存
export const trallProductDeleteApi = '/trall/product/delete'			// 产品删除

export const trallProducttypeListApi = '/trall/producttype/list'				// 产品类型列表
export const trallProducttypeEditApi = '/trall/producttype/edit'				// 产品类型编辑
export const trallProducttypeSaveApi = '/trall/producttype/save'				// 产品类型保存
export const trallProducttypeDeleteApi = '/trall/producttype/delete'			// 产品类型删除
export const trallProducttypeCheckeditApi = '/trall/producttype/checkedit' 		// 产品类型检测要求
export const trallProducttypeChecksaveApi = '/trall/producttype/checksave' 		// 保存产品类型检测要求
export const trallProducttypeCheckdeleteApi = '/trall/producttype/checkdelete'	// 删除产品类型检测要求

/******************生物样本获取**********************/
export const trallProductListnewApi = '/trall/product/newlist'				// 生物样本产品列表
export const trallProductEditnewApi = '/trall/product/newedit'				// 生物样本产品编辑
export const trallProductSavenewApi = '/trall/product/newsave'				// 生物样本产品保存

/******************二维码管理**********************/
export const qrcodeApplyListApi = '/qrcode/apply/list'			// 二维码申请列表
export const qrcodeApplyEditApi = '/qrcode/apply/edit'			// 二维码申请添加、编辑
export const qrcodeApplySaveApi = '/qrcode/apply/save'			// 二维码申请保存
export const qrcodeApplyDeleteApi = '/qrcode/apply/delete'		// 二维码申请删除
export const qrcodeApplyUselistApi = '/qrcode/apply/uselist'	// 二维码使用情况
export const qrcodeApplyItemUseApi = '/qrcode/apply/getitemuse'	// 项目二维码使用情况
export const qrcodeApplySigUseApi = '/qrcode/apply/getitemsiglist' // 项目中心二维码使用情况
export const qrcodeCountSigcountApi = '/qrcode/apply/getsiglist' // 中心二维码使用统计
/******************频率管理**********************/
export const trallFrequencyListApi = '/trall/frequency/list'			// 频率列表
export const trallFrequencyEditApi = '/trall/frequency/edit'			// 频率添加、编辑
export const trallFrequencySaveApi = '/trall/frequency/save'			// 频率保存
export const trallFrequencyDeleteApi = '/trall/frequency/delete'		// 频率删除

/******************项目流程初始设置**********************/
export const itemDicstepListApi = '/item/dicstep/list'			// 项目流程初始设置列表
export const itemDicstepEditApi = '/item/dicstep/edit'			// 项目流程初始设置添加、编辑
export const itemDicstepSaveApi = '/item/dicstep/save'			// 项目流程初始设置保存
export const itemDicstepDeleteApi = '/item/dicstep/delete'		// 项目流程初始设置删除

/******************项目合作方管理**********************/
export const itemSignatoryListApi = '/item/signatory/list'			// 项目合作方列表
export const itemSignatoryEditApi = '/item/signatory/edit'			// 项目合作方添加、编辑
export const itemSignatorySaveApi = '/item/signatory/save'			// 项目合作方保存
export const itemSignatoryDeleteApi = '/item/signatory/delete'		// 项目合作方删除

export const itemSignatoryTreeApi = '/item/signatory/itemsigtree'	// 项目合作方树状信息

/******************项目合作方过程管理**********************/
export const itemStepListApi = '/item/step/list'			// 项目合作方过程列表
export const itemStepEditApi = '/item/step/edit'			// 项目合作方过程添加、编辑
export const itemStepSaveApi = '/item/step/save'			// 项目合作方过程保存
export const itemStepDeleteApi = '/item/step/delete'		// 项目合作方过程删除

/******************项目合作方过程人员管理**********************/
export const itemStepUserListApi = '/item/user/list'		// 项目过程人员列表
export const itemStepUserEditApi = '/item/user/edit'		// 项目过程人员添加、编辑
export const itemStepUserSaveApi = '/item/user/save'		// 项目过程人员保存
export const itemStepUserDeleteApi = '/item/user/delete'		// 项目过程人员删除
export const itemStepSiguserApi = '/item/user/siguser'	// 中心人员的列表
export const itemStepUserMoresaveApi = 'item/user/moresave' // 项目过程保存选择人员
/******************上传附件**********************/
export const uploadFileApi = '/app/upload' // 上传附件
export const showUpFileApi = '/app/upload/showImg' // 显示附件
export const showQrcodeApi = '/app/upload/qrcode' // 显示二维码

/******************登录**********************/
export const scanQrcodeApi = '/app/login/scanqrcode' // 获取登录二维码
export const scanAskloginApi = '/app/login/asklogin'  // 轮询登录状态

/******************H5菜单管理**********************/
export const h5MenuListApi = '/acl/appletsmenu/list' // H5菜单列表
export const h5MenuEditApi = '/acl/appletsmenu/edit' // H5菜单编辑
export const h5MenuSaveApi = '/acl/appletsmenu/save' // H5菜单保存

/******************设备管理**********************/
export const trallEquipmentListApi = '/trall/equipment/list' // 设备管理列表
export const trallEquipmentEditApi = '/trall/equipment/edit' // 设备管理编辑
export const trallEquipmentSaveApi = '/trall/equipment/save' // 设备管理保存
export const trallEquipmentDeleteApi = '/trall/equipment/delete' // 设备管理删除

/******************中心角色设置**********************/
export const signatoryRoleListApi = '/signatory/role/list' // 中心角色列表
export const signatoryRoleEditApi = '/signatory/role/edit' // 中心角色编辑
export const signatoryRoleSaveApi = '/signatory/role/save' // 中心角色保存
export const signatoryRoleDeleteApi = '/signatory/role/delete' // 中心角色删除

/******************中心角色人员设置**********************/
export const signatoryManagerListApi = '/signatory/info/mangersiglist'	// 中心管理员管理的中心列表
export const signatoryUserEditApi = '/signatory/user/edit'	// 中心角色人员设置
export const signatoryUserSaveApi = '/signatory/user/save'	// 中心角色人员保存

/******************导出产品使用的PDF**********************/
export const downloadProductPdfApi = '/item/use/exportpdf'

/******************导出二维码**********************/
export const qrcodeDownload = '/qrcode/apply/downcode'

/******************人员类型设置**********************/
export const UserTypeListApi = '/acl/user/typelist'	// 人员类型列表
export const UserTypeEditApi = '/acl/user/typeedit'	// 人员类型管理
export const UserTypeSaveApi = '/acl/user/typesave'	// 保存人员类型
export const UserTypeDeleteApi = '/acl/user/typedelete'	// 人员类型删除

/******************项目类型设置**********************/
export const itemTypeListApi = '/item/type/typelist' // 项目类型列表
export const itemTypeEditApi = '/item/type/typeedit' // 项目类型管理
export const itemTypeSaveApi = '/item/type/typesave' // 项目类型保存
export const itemTypeDeleteApi = '/item/type/typedelete' // 项目类型删除

/******************项目类型设置**********************/
export const systemCopyrightEditApi = '/system/copyright/edit' // 版权设置
export const systemCopyrightSaveApi = '/system/copyright/save' // 保存版权信息
export const systemCopyrightDeleteApi = '/system/copyright/delete' // 删除版权信息
export const copyrightApi = '/app/login/copyright' // 获取版本信息（不包含id）

/******************产品使用过程管理**********************/
export const itemUseStepListApi= '/item/use/historystep' // 产品使用过程列表
export const itemUseStepEditApi = '/item/use/allstepedit' // 产品使用过程编辑
export const itemUseStepSaveApi = '/item/use/saveallstep' // 产品使用过程保存

/******************单位（公司）信息管理**********************/
export const companyListApi = '/acl/company/list' // 单位列表
export const companyEditApi = '/acl/company/edit' // 单位信息管理
export const companySaveApi = '/acl/company/save' // 保存单位信息
export const companyDeleteApi = '/acl/company/delete' // 删除单位信息
