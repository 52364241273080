export default {
	namespaced: true,
	state: {
		FormOptions: {
			labelWidth: '120px',
			labelPosition: 'right', 	// left | right | top
			disabled: false,
			size: 'large', 	// 'large' | 'small' | 'default',
			showResetButton: false, // 是否展示重置按钮
			showCancelButton: false, // 是否展示取消按钮
			showDeleteButton: true,	// 是否展示删除按钮
			submitButtonText: '立即提交',
			resetButtonText: '表单重置',
			cancelButtonText: '取消操作',
			deleteButtonText: '删 除'
		}
	}
}
