export default {
	namespaced: true,
	state: {
		'dialogOptions': {
			width: '95%',
			modal: true,
			title: '',
			'append-to-body': true,
			'lock-scroll': true,
			'align-center': true,
			'destroy-on-close': true
		}
	}
}
