import { createStore } from 'vuex'
import LeftMenuNav from '@/store/leftMenuNav'
import CustomForm from "@/store/CustomForm"
import CustomTable from "@/store/CustomTable"
import CustomTree from "@/store/CustomTree"
import CustomDialog from "@/store/CustomDialog"
import httpRequest from "@/utils/request";
import {copyrightApi} from "@/utils/api";

const modules = {
	LeftMenuNav,
	CustomForm,
	CustomTable,
	CustomTree,
	CustomDialog
}

let store = createStore({
	state: {
		'domain': 'kingdone.com',
		'IPC': '京ICP备20021033号-4',
		'del_confirm_msg': '您确定要删除要此信息么？删除后数据将不可恢复!',
		'routesList': [],
		'userInfo': {},
		'breadcrumb': [],	// 面包屑信息
		'typeList': [
			{ id: 1, label: '产 品'},
			{ id: 2, label: '箱 子'},
		],
		'qrcodeImg': {},
		'copyright': {}
	},
	mutations: {
		setRoutesList(state, routesList) {
			state.routesList = {
				path: '/',
				name: 'Home',
				component: () => import('@/pages/home/myHome'),
				children: routesList
			}
		},
		setUserInfo(state, userInfo) {
			state.userInfo = userInfo
		},
		setCopyrightInfo(state, copyrightInfo) {
			state.copyright = copyrightInfo
		}
	},
	getters: {
	
	},
	actions: {
		copyrightInfo(context) {
			httpRequest(copyrightApi).then( res => {
				context.commit("setCopyrightInfo", res.data)
			})
		}
	},
	modules
})

export default store
