export default {
	namespaced: true,
	state: {
		treeOptions: {
			indent: 16,
			highlightCurrent: true,
			expandOnClickNode: false,
			checkOnClickNode: true,
			showCheckbox: false,
			checkStrictly: false,
			icon: '',
			nodeKey: 'id'
		}
	}
}
