import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.min.mjs'
import * as ElementPlusIconsVue from "@element-plus/icons-vue"

import 'element-plus/dist/index.css'
import './assets/css/main.css'

const app = createApp(App)

app.config.productionTip = false
let comp, iconName;

for ([iconName, comp] of Object.entries(ElementPlusIconsVue)) {
	app.component(iconName, comp);
}
app.use(ElementPlus, { size: 'small', zIndex: 3000, locale: zhCn })
app.use(router)
app.use(store)

// 挂载
app.mount('#app')
