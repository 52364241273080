import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store";

const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/pages/login'),
		meta: {
			noCheckLogin: true
		}
	},
	{
		path: '/',
		name: 'Home',
		component: () => import('@/pages/home/myHome'),
		children: []
	}
	/*{
		path: '/',
		name: 'home',
		component: () => import('@/pages/home/myHome'),
		children: [
			{
				path: '/acl/menu',
				name: 'AclMenu',
				component: () => import('@/pages/acl/menu/index'),
			},
			{
				path: '/acl/user',
				name: 'AclUser',
				component: () => import('@/pages/acl/user/index'),
			},
			{
				path: '/acl/role',
				name: 'AclRole',
				component: () => import('@/pages/acl/role/index'),
			},
			{
				path: '/signatory/info',
				name: 'SignatoryInfo',
				component: () => import('@/pages/signatory/info/index'),
			},
			{
				path: '/item/info',
				name: 'ItemInfo',
				component: () => import('@/pages/item/info/index'),
			},
			{
				path: '/item/state',
				name: 'ItemState',
				component: () => import('@/pages/item/state/index'),
			},
			{
				path: '/trall/box',
				name: 'TrallBox',
				component: () => import('@/pages/trall/box/index'),
			},
			{
				path: '/trall/product',
				name: 'TrallProduct',
				component: () => import('@/pages/trall/product/index'),
			},
			{
				path: '/trall/producttype',
				name: 'TrallProducttype',
				component: () => import('@/pages/trall/producttype/index'),
			},
			{
				path: '/qrcode/apply',
				name: 'QrcodeApply',
				component: () => import('@/pages/qrcode/apply/index'),
			},
			{
				path: '/qrcode/use',
				name: 'QrcodeUse',
				component: () => import('@/pages/qrcode/use/index'),
			}
		]
	}*/
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

// 路由前置守卫
router.beforeEach(async (to, form, next) => {
	if(!to.meta.noCheckLogin) {
		// 验证登录
		if(!localStorage.getItem('access_token')) {
			next('/login')
		}else {
			if(store.state.LeftMenuNav.leftNavMenuData.length === 0) {
				await store.dispatch("LeftMenuNav/getLeftMenus").then(() => {
					router.addRoute(store.state.routesList)
					
					next({
						...to,
						replace: true
					})
				})
			}
		}
	}else {
		if(localStorage.getItem('access_token')) next('/');
	}
	
	if(Object.values(store.state.copyright).length === 0 ) {
		store.state.copyright = {
			isload: 1
		}
		// 获取版权信息
		await store.dispatch("copyrightInfo")
	}
	console.log(store.state.copyright)
	
	store.state.breadcrumb = to.meta.title
	next()
})

export default router
