import store from "@/store"
import httpRequest from "@/utils/request"
import {ElMessage, ElMessageBox} from "element-plus"
import {uploadFileApi} from "@/utils/api";

export function postDelete(url, params) {
	return ElMessageBox.confirm(
		store.state.del_confirm_msg,
		'提示',
		{
			confirmButtonText: '确定删除',
			cancelButtonText: '取消删除',
			type: 'warning',
			buttonSize: 'default'
		}
	).then(() => {
		return httpRequest(url, params).then(() => {
			ElMessage({
				showClose: true,
				message: '删除成功',
				type: 'success'
			})
		})
	})
}

export async function postSubmit(url, params) {
	let res = await httpRequest(url, params);
	ElMessage({
		showClose: true,
		type: 'success',
		message: '保存成功'
	})
	return res
}

export async function uploadFile(params) {
	const formData = new FormData()
	formData.append('sdm_file', params)
	return await httpRequest(uploadFileApi, formData, 'file')
}

/**
 *  文件下载
 * @param downloadFileApi
 * @param params
 */
export function dowloadFile(downloadFileApi, params) {
	httpRequest(downloadFileApi, params, 'download')
}


export function initBackendRoutesData(routesData) {
	let initRoutesData = []
	routesData.map(item => {
		if(item.children) {
			item.children.map(child => {
				const route = {
					path: child.menuUrl.substr(1),
					name: child.menuUrl,
					component: () => import(`@/pages${child.menuUrl}/index`),
					meta: {
						title: [item.menuName, child.menuName]
					}
				}
				if(child.menuUrl.split('/').length === 4) route.component = () => import(`@/pages${child.menuUrl}`)
				initRoutesData.push(route)
			})
		}
	})

	return initRoutesData
}
