import axios from 'axios'
import qs from 'qs'
import { ElMessage} from "element-plus";
import router from "@/router";

function axiosRequest(url, data, method, config) {
	let contentType = 'application/x-www-form-urlencoded'
	let responseType = 'json'
	let params = {}
	method = method.toLowerCase()
	
	switch (method) {
		case 'get':
			params = {...data}
			data = {}
			break
		case 'post':
			data = qs.stringify(data)
			break
		case 'file':	// 上传文件
			contentType = 'multipart/form-data'
			method = 'post'
			break
		case 'download':
			responseType = 'blob'
			data = qs.stringify(data)
			method = 'post'
			break
	}
	
	const axiosConfig = {
		baseURL: process.env.VUE_APP_HTTP_BASE_URL,
		method,
		url,
		data,
		params,
		header: {
			'Content-Type': contentType
		},
		responseType,
	}

	if(config instanceof Object && Object.keys(config).length){
		config.forEach((item, index) => {
			axiosConfig[index] = item
		})
	}
	
	const axiosService = axios.create()
	// 请求拦截
	axiosService.interceptors.request.use(config => {
		// 请求头添加token
		if( localStorage.getItem('access_token') ) config.headers.Token = localStorage.getItem('access_token')
		return config
	}, error => {
		return Promise.reject(error)
	})
	
	// 响应拦截
	axiosService.interceptors.response.use(response => {
		if(response.data instanceof Blob) {
			const { data, headers } = response
			let fileName = headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1').replaceAll('"','')
			fileName = fileName.split('filename*=UTF-8\'\'')[1]
			console.log(fileName)
			// 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
			//const blob = new Blob([JSON.stringify(data)], ...)
			const blob = new Blob([data], {type: headers['content-type']+';charset=utf-8'})
			let dom = document.createElement('a')
			let url = window.URL.createObjectURL(blob)
			dom.href = url
			console.log(fileName, headers)
			dom.download = decodeURI(fileName)
			dom.style.display = 'none'
			document.body.appendChild(dom)
			dom.click()
			dom.parentNode.removeChild(dom)
			window.URL.revokeObjectURL(url)
			return Promise.reject()
		}else if(response.data.code !== 200) {
			ElMessage({
				type: 'error',
				showClose: true,
				message: response.data.msg,
			})
			if(response.data.code === 401) {
				localStorage.removeItem('access_token')
				router.push({
					name: 'login'
				})
			}
			return Promise.reject()
		}else {
			return Promise.resolve(response)
		}
	}, error => {
		ElMessage({
			type: 'error',
			showClose: true,
			message: '服务器发生错误',
		})
		return Promise.reject(error)
	})
	
	return axiosService.request(axiosConfig)
}

export default function httpRequest(url, data, method = 'post', config = {}) {
	return axiosRequest(url, data, method, config).then(res => {
		return res.data
	}).catch()
}
